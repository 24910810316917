import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const Image = ({ image, imageSharp }) => {
    if (!image && !imageSharp) return null;

    let src = image.url;
    let srcSet = '';
    if (imageSharp) {
        src = get(imageSharp, 'childImageSharp.fluid.src', image.url);
        srcSet = get(imageSharp, 'childImageSharp.fluid.srcSet', '');
    }
    return <StyledImage alt={image.alt} src={src} srcSet={srcSet} />;
};

export default Image;

import styled from 'styled-components';
import { generateBreakpoint, generateCol, generateGrid } from '@layouts/grid';
import { generateVwPercentageHeight } from '@src/helpers';
import colors from '@shared/colors';
import blueBlackPattern from '@src/images/bg/blue-black-pattern.svg';

export const InfoWrapper = styled.div`
    ${generateGrid(12, 0)}

    padding-top: 60px;

    > :nth-child(n) {
        ${generateCol(12)}

        ${generateBreakpoint('lg')(
            `${generateCol(6)}
            grid-auto-rows: minmax(min-content, max-content);
            `,
        )}
    }

    ${generateBreakpoint('lg')(
        `padding: 0;
        `,
    )}
`;

export const BlueBlackBlock = styled.div`
    background-image: url(${blueBlackPattern});
    width: 100%;
    order: 2;
    ${generateVwPercentageHeight(170, 170, 320)}

    ${generateBreakpoint('lg')(
        `${generateVwPercentageHeight(445, 445, 1024)}
        order: 3`,
    )}

    ${generateBreakpoint('xl')(generateVwPercentageHeight(480))}
`;

export const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    order: 4;
    ${generateVwPercentageHeight(198, 198, 320)}

    ${generateBreakpoint('lg')(
        `${generateVwPercentageHeight(445, 445, 1024)}
        order: 2;`,
    )}

    ${generateBreakpoint('xl')(generateVwPercentageHeight(480))}
`;

export const StyledInner = styled.div`
    position: absolute;

    width: 1020px;
    height: 630px;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
        position: relative;
        z-index: 3;
        height: 100%;
        width: 100%;
    }
`;

export const TitleBlock = styled.div`
    order: 1;

    font-family: 'SuisseIntl';
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: -0.42px;

    padding-left: 12px;
    padding-right: 12px;

    padding-bottom: 36px;

    ${generateBreakpoint('lg')(
        `font-size: 34px;
        letter-spacing: -0.6px;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            width: 80%;
        }`,
    )}

    ${generateBreakpoint('xl')(
        `font-size: 40px;
        line-height: 1.1;`,
    )}
`;

export const InfoInnerWrapper = styled.div`
    ${generateBreakpoint('lg')(`width: 80%;`)}
`;

export const InfoBlockInner = styled.div`
    font-family: 'SuisseIntl';
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: 0.18px;

    p {
        margin-bottom: 1em;
    }

    ${generateBreakpoint('lg')(
        `font-size: 19px;
        line-height: 1.42;
        letter-spacing: 0.29px;`,
    )}
`;

export const InfoBlockLinks = styled.div`
    padding-top: 20px;
    padding-bottom: 35px;

    > div {
        margin-bottom: 30px;
    }

    h3 {
        font-family: 'SuisseIntl';
        font-size: 14px;
        line-height: 1.93;
        letter-spacing: 0.21px;

        text-transform: uppercase;
    }

    a,
    a:visited,
    a:active {
        font-size: 14px;
        line-height: 1.93;
        letter-spacing: 0.21px;
        color: ${colors.mauve};
    }
`;

export const InfoBlock = styled.div`
    order: 3;

    padding-top: 36px;

    padding-left: 12px;
    padding-right: 12px;

    ${generateBreakpoint('lg')(
        `order: 4;
        display: flex;
        align-items: center;
        justify-content: center;`,
    )}
`;

import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import { RichText } from '@components/shared';
import Image from '@components/image';
import infoImage from '@src/images/bg/info-page-foto.jpg';
import {
    BlueBlackBlock,
    ImageWrapper,
    StyledInner,
    TitleBlock,
    InfoBlock,
    InfoInnerWrapper,
    InfoBlockInner,
    InfoBlockLinks,
    InfoWrapper,
} from './info.styled';

const InfoPage = ({ data, pageContext }) => {
    if (!data || !pageContext) {
        return null;
    }
    const {
        prismic: {
            infopage: { title, content, info_links },
        },
    } = data;

    return (
        <InfoWrapper>
            <TitleBlock>
                {title && title.length ? <h1>{title[0].text}</h1> : null}
            </TitleBlock>
            <ImageWrapper>
                <StyledInner>
                    <Image
                        image={{
                            url: infoImage,
                            alt: 'Photo of the Future Intel team',
                        }}
                    />
                </StyledInner>
            </ImageWrapper>
            <BlueBlackBlock />
            <InfoBlock>
                <InfoInnerWrapper>
                    <InfoBlockInner>
                        {content && content.length ? (
                            <RichText render={content} />
                        ) : null}
                    </InfoBlockInner>
                    <InfoBlockLinks>
                        {info_links && info_links.length
                            ? info_links.map((link, i) => (
                                  <div key={`info_link_${i}`}>
                                      <h3>{link.link_title}</h3>
                                      <a href={get(link, 'link_url.url', '#')}>
                                          {link.link_label}
                                      </a>
                                  </div>
                              ))
                            : null}
                    </InfoBlockLinks>
                </InfoInnerWrapper>
            </InfoBlock>
        </InfoWrapper>
    );
};

export const query = graphql`
    query GetInfoPage($uid: String!) {
        prismic {
            infopage(lang: "en-au", uid: $uid) {
                title
                content
                info_links {
                    link_url {
                        ... on PRISMIC__ExternalLink {
                            _linkType
                            url
                        }
                    }
                    link_title
                    link_label
                }
            }
        }
    }
`;

export default InfoPage;
